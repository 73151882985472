import React, {useState, useEffect} from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import Background from "../images/home/Background.webp"
import BackgroundMov from "../images/home/BackgroundMov.webp"
import useWindowSize from "../hooks/useWindowSize"
import CTA from "../components/buttons/cta"
import FirstImage from "../images/sistema/FirstSection.webp"
import Calendar from "../components/contacto/calendar"
import Card from "../components/contacto/card"
import Whats from "../images/layout/Whats.webp"
import Mail from "../images/layout/Mail.webp"
import Location from "../images/layout/Location.webp"
import Enviar from "../components/buttons/enviar"
import "../css/typography.css"

const FirstSection =styled.div`
    color: white;
    background-image: url(${Background});
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5%;
    padding-bottom: 3%;

    h1 {
        font-family: 'A Black';
        font-size: 32px;
        margin-bottom: 0;
    }
    p {
        font-family: 'A Regular';
        font-size: 20px;
        margin-bottom: 0;
        line-height: 25px;
    }
    img {
        width: 600px;
        margin-top: 10px;
    }
    >div:nth-child(1){
        display: flex;
        flex-direction: column;
    }
    >div:nth-child(1)>div{
        display: flex;
        margin-top: 5%;
        justify-content: space-between;
        width: 400px;
    }

    @media only screen and (min-width: 1200px) {  
        padding-bottom: 5%;

        h1 {
        font-size: 45px;
        }
        p {
        width: 460px;
        font-size: 23px;
        margin-bottom: 15px;
        }
    }
    @media only screen and (min-width: 1500px) {  
        padding-bottom: 5%;

        h1 {
        font-size: 50px;
        }
        p {
        width: 760px;
        font-size: 25px;
        }
        >div:nth-child(1){
        width:700px;
        }
    }
    @media only screen and (min-width: 1800px) {  
        h1 {
        font-size: 60px;
        margin-top: 80px;
        }
        p {
        width: 760px;
        font-size: 30px;
        }
        img {
        width: 900px;
        } 
        >div:nth-child(1)>div{
        width:470px;
        }
    }
    @media only screen and (min-width: 2500px) {  
        h1 {
        font-size: 70px;
        margin-top: 80px;
        }
        p {
        width: 1000px;
        font-size: 45px;
        line-height: 50px;
        }
        img {
        width: 1200px;
        } 
        >div:nth-child(1){
        width: 1000px;
        }
        >div:nth-child(1)>div{
        width:620px;
        }
    }
    @media only screen and (min-width: 3500px) {  
        h1 {
        font-size: 100px;
        margin-top: 80px;
        }
        p {
        width: 1300px;
        font-size: 55px;
        line-height: 60px;
        }
        img {
        width: 1500px;
        } 
        >div:nth-child(1){
        width: 1500px;
        }
        >div:nth-child(1)>div{
        width:820px;
        }
    }
    @media only screen and (min-width: 4000px) {  
        h1 {
        font-size: 130px;
        margin-top: 80px;
        }
        p {
        width: 1700px;
        font-size: 70px;
        line-height: 70px;
        }
        img {
        width: 2000px;
        } 
        >div:nth-child(1){
        width: 2000px;
        }
        >div:nth-child(1)>div{
        width: 1050px;
        }
    }
    @media only screen and (max-width: 1115px) {  
        padding-bottom: 5%;

        img {
            width: 500px;
        }
    }
    @media only screen and (max-width: 1015px) {  
        padding-bottom: 15%;

        img {
            width: 400px;
        }
    }
    @media only screen and (max-width: 820px) {  
        flex-direction: column;
        padding: 5%;

        img {
            width: 70vw;
            margin-right: -30vw;
        }
        >div:nth-child(1){
            align-items: center;    
        }
        h1 {
            align-self: flex-start;
        }
    }
    @media only screen and (max-width: 540px) {  
        background-image: url(${BackgroundMov});
        padding: 10%;

        h1 {
            font-size: 7vw;
        }
        p {
            font-size: 5.5vw;
            line-height: 30px;
        }
        img {
            width: 130vw;
            margin-left: -16vw;
        }
        >div:nth-child(1)>div{
            width: 90vw;
            }
        }
`

const CalendarDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        font-family: 'A Black';
        color: #092247;
        font-size: 25px;
        margin-bottom: 0;
    }
    >div {
        border-bottom: 4px solid #49B9E9;
        height:1px;
        width: 50px;
        border-radius: 5x;
    }
    @media only screen and (min-width: 1200px) {  
        padding-bottom: 5%;

        h2 {
        font-size: 45px;
        }
    }
    @media only screen and (min-width: 1500px) {  
        padding-bottom: 5%;

        h2 {
        font-size: 50px;
        }
    }
    @media only screen and (min-width: 1800px) {  
        h2 {
        font-size: 60px;
        margin-top: 80px;
        }
    }
    @media only screen and (min-width: 2500px) {  
        h2 {
        font-size: 70px;
        margin-top: 80px;
        }
    }
    @media only screen and (min-width: 3500px) {  
        h2 {
        font-size: 100px;
        margin-top: 80px;
        }
    }
    @media only screen and (min-width: 4000px) {  
        h2 {
        font-size: 130px;
        margin-top: 80px;
        }
    }
    @media only screen and ( max-width: 800px) {
        >div {
            margin-bottom: 25px;
        }
    @media only screen and ( max-width: 700px) {
        >div {
            margin-bottom: 25px;
        }
    @media only screen and ( max-width: 600px) {
        >div {
            margin-bottom: 25px;
        }
    @media only screen and ( max-width: 500px) {
        >div {
            margin-bottom: 25px;
        }
    @media only screen and ( max-width: 400px) {
        >div {
            margin-bottom: 20px;
        }
  
`

const CardsDiv = styled.div`
    background-color: #092247;
    display: flex;
    justify-content: space-around;
    padding: 3%;
    margin-bottom: 3%;

    @media only screen and (max-width: 855px) {
        flex-direction: column;
        align-items: center;
    }
`

const FirstForm = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3%;
    padding-bottom: 5%;

    h2 {
        font-family: 'A Black';
        color: #0B2A3B;
        font-size: 25px;
    }
    form  {
        display: flex;
        flex-direction: column;
    }
    form > input {
        border: none;
        background-color: #EBEBEB;
        color: black;
        border-radius: 10px;
        height: 40px;
        width: 850px;
        box-shadow: 0 4px 7px -2px gray;
        font-family: 'A Medium';
        font-size: 18px;
        padding-left: 10px;
        margin-bottom: 20px;
    }
    form > textarea {
        border: none;
        background-color: #EBEBEB;
        border-radius: 10px;
        height: 200px;
        width: 850px;
        box-shadow: 0 4px 7px -2px gray;
        font-family: 'A Medium';
        font-size: 18px;
        padding-left: 10px;
    }
    form > div {
        display: flex;
        justify-content: center;
        margin-top: 3%;
    }
    @media only screen and (min-width: 1200px) {
         h2 {
            width: 700px;
            font-size: 35px;
            text-align: center;
        }
        form > input{
            width: 850px;
            height: 40px;
            font-size: 25px;
            margin-bottom: 25px;
        }
        form > textarea{
            width: 850px;
            height: 150px;
            font-size: 25px;
        }
    }
    @media only screen and (min-width: 1800px) {
         h2 {
            width: 950px;
            font-size: 55px;
        }
        form > input{
            width: 1100px;
            height: 80px;
            margin-bottom: 30px;
        }
        form > textarea{
            width: 1100px;
            height: 150px;
        }
    }
    @media only screen and (min-width: 2500px) {
        h2 {
            width: 1200px;
            font-size: 65px;
        }
        form > input{
            width: 1500px;
            height: 110px;
            font-size: 30px;
            margin-bottom: 50px;
        }
        form > textarea{
            width: 1500px;
            height: 250px;
            font-size:30px;
        }
    }
    @media only screen and (min-width: 3500px) {
        h2 {
            width: 1750px;
            font-size: 95px;
        }
        form > input{
            width: 1900px;
            height: 120px;
            font-size:40px;
            margin-bottom: 70px;
        }
        form > textarea{
            width: 1900px;
            height: 300px;
            font-size:40px;
        }
    }
    @media only screen and (min-width: 4000px) {
        h2 {
            width: 2150px;
            font-size: 125px;
        }
        form > input{
            width: 2300px;
            height: 130px;
            font-size:50px;
            margin-bottom: 70px;
        }
        form > textarea{
            width: 2300px;
            height: 400px;
            font-size:50px;
        }
        >div:nth-child(2) div.enviar {
            font-size: 50px;
        }
    }
    @media only screen and (max-width: 913px) {

        form {
            width: 800px;
            
        }
        form > input {
            width: 800px;
        }
        form > textarea{
            width: 800px;
        }
        form > div {
            height: 80px;
        }
    }
    @media only screen and (max-width: 813px) {

        form {
            width: 700px;
            
        }
        form > input {
            width: 700px;
        }
        form > textarea{
            width: 700px;
        }
        form > div {
            height: 70px;
        }
    }
    @media only screen and (max-width: 713px) {

        form {
            width: 600px;
            
        }
        form > input {
            width: 600px;
        }
        form > textarea{
            width: 600px;
        }
        form > div {
            height: 60px;
        }
    }
    @media only screen and (max-width: 613px) {

        form {
            width: 550px;
            justify-content: center;
        }
        form > input {
            width: 550px;
        }
        form > textarea{
            width: 550px;
        }
    }
    @media only screen and (max-width: 513px) {

        form {
            width: 400px;
            justify-content: center;
        }
        form > input {
            width: 400px;
        }
        form > textarea{
            width: 400px;
        }
    }
    @media only screen and (max-width: 413px) {

        form {
            width: 350px;
            justify-content: center;
        }
        form > input {
            width: 350px;
        }
        form > textarea{
            width: 350px;
        }
    }
`

const Contacto = () => {
    const [hasMounted, setHasMounted] = useState(false) 
    const windowSize = useWindowSize()

    useEffect(() => {
        setHasMounted(true)
    }, [])

return hasMounted ? (        
    <Layout>
        <FirstSection>
            <div>
                <h1>CONTÁCTANOS</h1>
                <p>¿La supervisión de tu empresa es un área de oportunidad?</p>
                <p> ¡Cámbialo con Ventum!</p>
                <div>
                    <CTA text='Conoce más' link='/nuestro-sistema' />
                </div>
            </div>
            <div>
                <img src={FirstImage} alt="First Image" />
            </div>
        </FirstSection>
        <FirstForm>
            <h2>¡Queremos aclarar todas tus dudas! ¡Contáctanos!</h2>
            <form 
                action="https://formspree.io/f/mvolydkw"
                method="POST"
            >
                <input type="text" name='nombre' placeholder="Nombre" />
                <input type="email" name="email" placeholder="Correo" />
                <input type="text" name='telefono' placeholder="Teléfono con clave lada" />
                <textarea cols="30" rows="10" name='mensaje' placeholder="Mensaje"></textarea>
                <div>
                    < Enviar />
                </div>
            </form>
        </FirstForm>
        <CardsDiv>
            <Card image={Mail} text='contacto@ventumsupervision.com ' />
            <Card image={Whats} text='5615084111' />
            {/* <Card image={Location} text='Ejército Nacional Este, Avenida General Mariano Escobedo Oeste' /> */}
        </CardsDiv>
        <CalendarDiv>
            <h2>¡SOLICITA TU DEMO!</h2>
            <div></div>
            <Calendar /> 
        </CalendarDiv>
    </Layout>
    ) : (
    null
    )
}

export default Contacto
