import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import styled from "styled-components"
import {CaretRightOutlined, CaretLeftOutlined} from '@ant-design/icons'
import 'react-calendar/dist/Calendar.css';
import "../../../css/calendar.css"
import Enviar from "../../buttons/enviar"

const Hora = styled.div`
    width: 60vw;
    background-color: #092247;
    padding: 1vw;
    padding-bottom: 2vw;
    border-radius: 10px;
    box-shadow: 0 4px 7px -2px gray;

    > p:nth-child(1) {
        font-family: 'A Black';
        color: white;
        font-size: 1.4vw;
    }

    > div {
        display: flex;
        justify-content: space-between;
    }
    > div > div {
        width: 7vw;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3vw;
        border-radius: 10px;
        cursor: pointer;
    }
    > div > div p {
        font-family: 'A Black';
        font-size: 1vw;
    }
    @media only screen and (min-width: 1200px) {
        width: 56vw;
        height: 4.5vw;
        padding: 2vw;
        padding-bottom: 4vw;
        border-radius: 20px;

    > p:nth-child(1) {
        font-size: 1.5vw;
        
    }

    > div > div {
        width: 8vw;
        height: 2vw;
        border-radius: 10px;
    }
    > div > div p {
        font-family: 'M Bold';
        font-size: 1.5vw;
    }
    }
    @media only screen and (max-width: 480px) {
        width: 86vw;
        padding: 2vw;
        padding-bottom: 4vw;
        border-radius: 10px;

    > p:nth-child(1) {
        font-size: 3.5vw;
    }

    > div > div {
        width: 12vw;
        height: 5vw;
        border-radius: 10px;
    }
    > div > div p {
        font-family: 'A Black';
        font-size: 3vw;
    }
    }
    @media only screen and (max-width: 800px) {
        width: 86vw;
        padding: 2vw;
        padding-bottom: 4vw;
        border-radius: 10px;

    > p:nth-child(1) {
        font-size: 2.5vw;
    }

    > div > div {
        width: 12vw;
        height: 3.2vw;
        border-radius: 10px;
    }
    > div > div p {
        font-family: 'A Black';
        font-size: 2vw;
    }
    }
`

const CalendarComponent = () => {

    const [value, onChange] = useState(new Date());
    const [hour, setHour] = useState('08:00')

    const options = {year: 'numeric', month: '2-digit', day: 'numeric'};
    const monthOptions = {year: 'numeric', month: 'long'};

    return (
        <form className='contact-demo-form'
            action="https://formspree.io/f/mrgjdrpr"
            method="POST"
        >
            <div>
                <p>Día:*</p>
                <Calendar
                    onChange={onChange}
                    value={value}
                    locale="es-MX"
                    nextLabel={<CaretRightOutlined className='calendar-prev-next-icons' />}
                    prevLabel={<CaretLeftOutlined className='calendar-prev-next-icons' />}
                    minDetail='month'
                    showNeighboringMonth={false}
                    formatMonthYear={(locale, date) => date.toLocaleDateString('es-MX', monthOptions).replace('de', '')}
                />                
            </div>
            <div>
                <p>Hora:*</p>
                <Hora>
                    <p>{value.toLocaleDateString('es-MX', options)}</p>
                    <div>
                        <div onClick={() => setHour('08:00')} style={{backgroundColor: hour === '08:00' ? '#49B9E9' : 'white'}}>
                            <p style={{color: hour === '08:00' ? 'white' : 'black', fontFamily: 'A Medium'}}>08:00</p>
                        </div>
                        <div onClick={() => setHour('09:00')} style={{backgroundColor: hour === '09:00' ? '#49B9E9' : 'white'}}>
                            <p style={{color: hour === '09:00' ? 'white' : 'black', fontFamily: 'A Medium'}}>09:00</p>
                        </div>
                        <div onClick={() => setHour('10:00')} style={{backgroundColor: hour === '10:00' ? '#49B9E9' : 'white'}}>
                            <p style={{color: hour === '10:00' ? 'white' : 'black', fontFamily: 'A Medium'}}>10:00</p>
                        </div>
                        <div onClick={() => setHour('11:00')} style={{backgroundColor: hour === '11:00' ? '#49B9E9' : 'white'}}>
                            <p style={{color: hour === '11:00' ? 'white' : 'black', fontFamily: 'A Medium'}}>11:00</p>
                        </div>
                        <div onClick={() => setHour('12:00')} style={{backgroundColor: hour === '12:00' ? '#49B9E9' : 'white'}}>
                            <p style={{color: hour === '12:00' ? 'white' : 'black', fontFamily: 'A Medium'}}>12:00</p>
                        </div>
                        <div onClick={() => setHour('13:00')} style={{backgroundColor: hour === '13:00' ? '#49B9E9' : 'white'}}>
                            <p style={{color: hour === '13:00' ? 'white' : 'black', fontFamily: 'A Medium'}}>13:00</p>
                        </div>
                    </div>
                </Hora>
            </div>
            <div>
                <p>Correo:</p>
                <input type="email" name="_replyto" required style={{marginBottom: '2vw'}} />
                <p>Teléfono:</p>
                    <input type="text" name="teléfono" required className='phone-input' />
                <input name="fecha" type="hidden" value={value.toLocaleDateString('es-MX', options)} />
                <input name="hora" type="hidden" value={hour} />
                <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                    <Enviar />
                </div>
            </div>
        </form>
    )
}

export default CalendarComponent
