import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import "../../../css/typography.css"

const Button = styled.input`
    background-color: #49BAEA !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 130px; 
    border-radius: 9px !important;
    font-family: 'A Heavy' !important;
    color: #FFFFFF !important;
    font-size: 20px ; 
    text-align: center !important;
    cursor: pointer;
    border: none;

    @media only screen and (min-width: 1200px) {
        height:50px;
        width: 100px;
        font-size: 20px; 
    }
    @media only screen and (min-width: 1500px) {
        height: 60px;
        width: 110px;
    }
    @media only screen and (min-width: 1800px) {
        
        height: 70px;
        width: 180px;
        font-size: 30px;
        
    }
    @media only screen and (min-width: 2500px) {
        
        height: 80px;
        width: 200px;
        font-size: 40px;
        
    }
    @media only screen and (min-width: 3500px) {
        
        height: 110px;
        width: 300px;
        font-size: 55px;
        
    }
    @media only screen and (min-width: 4000px) {
        
        height: 130px;
        width: 350px;
        font-size: 65px;

    }
    @media only screen and (max-width: 910px) {
        font-size: 15px !important;
    }
    ${'' /* @media only screen and (max-width: 540px) {
        width: 30vw;
        font-size: 4.5vw !important;
    } */}
`

const AppButton = (props) => {

    return  (
        <Button type='submit' value='Enviar' />
    )
}

export default AppButton
