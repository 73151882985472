import React from 'react'
import styled from "styled-components"
import "../../../css/typography.css"

const MainDiv = styled.div`
    display: flex;
    align-items: center;
    width: 400px;
    background-color: white;
    padding: 1%;

    img {
        margin-right: 15px;
        width: 55px;
        height: 55px;
    }
    p {
        font-family: 'A Regular';
        font-size: 18px;
        color: #000000;
        cursor: pointer;
    }
    @media screen and (min-width: 1200px) {
       
       width: 450px;
       height:80px;

       p {
           font-size: 25px;
           width: 350px;
       }
       img{
           width: 50px;
       }

        
    }
    @media screen and (min-width: 1800px) {

        width: 640px;
        height: 100px;

        p{
           font-size: 35px;
           width: 550px;
           margin-left: 10px;
       }
       img{
           margin-left: 5px;
           width: 80px;
           height:80px;
           
       }
    }
    @media screen and (min-width: 2500px) {

        width: 850px;
        height: 120px;

        p{
           font-size: 45px;
           width: 700px;
           margin-left: 10px;
       }
       img{
           width: 90px;
           width: 100px;
           height: 100px;
       }
    }
    @media screen and (min-width: 3500px) {
        
        width: 1200px;
        height: 150px;

        p{
           font-size: 63px;
           width: 890px;
       }
       img{
           width: 130px;
           height: 130px;
       }
    }
    @media screen and (min-width: 4000px) {
               
        width: 1400px;
        height: 220px;

        p {
           font-size: 80px;
           width: 1290px;

       }
       img{
           width: 160px;
           
       }
    }

    /* @media only screen and (max-width: 1350px) {
        width: 300px;
    } */
    @media only screen and (max-width: 1025px) {
        width: 300px;
        background-color: white;
        p {
            font-size: 15px;
        }
    }
    @media only screen and (max-width: 855px) {
        width: 60vw;
        margin-bottom: 20px;
        p {
            font-size: 18px;
        }
        img {
            width: 50px;
            height: 50px;
        }
    }
    @media only screen and (max-width: 755px) {
        width: 600px;
        p{
            font-size: 30px;
        }
        img{
            width: 65px;
            height: 65px;
        }
    }
    @media only screen and (max-width: 655px) {
        width: 500px;
        p{
            font-size: 25px;
        }
        img{
            width: 60px;
            height: 60px;
        }
    }
    @media only screen and (max-width: 555px) {
        width: 400px;
        p{
            font-size: 21px;
        }
    }
    @media only screen and (max-width: 455px) {
        width:300px;
        p{
            font-size:15px;
        }
    }
`

function Card(props) {
    return (
        <MainDiv>
            <img src={props.image} alt={props.alt} />
            <p>{props.text}</p>
        </MainDiv>
    )
}

export default Card